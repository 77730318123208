@import './mixins.scss';
@import './variables.scss';

.homepage-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  position: fixed;
  width: 100vw;
  height: 100vh;

  .background {
    filter: brightness(85%);
    filter: saturate(70%);
    z-index: -3;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    justify-self: center;
    text-align: center;
    color: $celeste;
    @include txt-shadow;

    h1 {
      font-size: 7.5em;
      padding: 0px;
      margin: 0px;
    }

    h2 {
      font-size: 2em;
      padding: 0px;
      margin: 0px;
    }

    .headshot-container {
      display: flex;
      justify-content: center;
      margin-top: 5%;

      .headshot {
        height: 500px;
        width: 333.3px;
        box-shadow: inset 0px -10px 40px 5px rgba(0, 0, 0, 2);
        border-radius: 5px;

        img {
          height: 100%;
          position: relative;
          z-index: -2;
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 2);
          border-radius: 5px;
        }
      }
    }
  }
}
