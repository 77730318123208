$bluegray: #688386;
$dark-teal: #0b424b;
$oxford: #00072d;
$caribbean-green: #3accaf;
$darker-green: #35a790;
$celeste: #c0fdfb;
$charcoal: #587079;
$warning: #922a2a;
$yellow: #edce00;
$light-gray: #beccd1;
