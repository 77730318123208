@import './mixins.scss';
@import './variables.scss';
@import 'media-queries.scss';

.contact-page-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  position: fixed;
  width: 100%;
  height: 100%;

  .background {
    filter: brightness(60%);
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    justify-content: center;
    text-align: center;
    text-shadow: 0px 0px 15px black;
    color: $celeste;

    .contact-me {
      font-size: 5em;
      font-weight: 700;
      padding-bottom: 100px;
    }

    .bullet-points {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 0px 357px 0px 300px;
      font-size: 2.4em;

      .icon {
        font-size: 2em;
        color: $caribbean-green;

        path {
          stroke: #082829;
          stroke-width: 15;
        }
      }
    }
  }
}
