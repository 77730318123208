@import './mixins.scss';
@import './variables.scss';
@import './media-queries.scss';

.playerContainer {
  display: flex;
  width: 700px;
  align-items: center;

  .forwardBackward {
    background: none;
    border: 2px solid $celeste;
    border-radius: 50%;
    height: 1.7em;
    width: 1.7em;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 1.2em;
    cursor: pointer;
    color: $celeste;
    transition: 0.4s;

    p {
      margin: 0;
      font-size: 0.5em;
    }

    &:hover {
      color: $darker-green;
      border-color: $darker-green;
    }
  }

  .playPause {
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    font-size: 1.7em;
    cursor: pointer;
    color: $celeste;
    transition: 0.4s;

    &:hover {
      color: $darker-green;
    }
  }

  .currentTime,
  .duration {
    font-size: 1.5em;
    width: 55px;
    height: 32px;
    padding: 0px 10px 0px 10px;
    position: relative;
  }

  .currentTime {
    padding-left: 20px;
  }

  // chrome
  .progressBar {
    --bar-bg: $celeste;
    --seek-before-width: 0;
    --seek-before-color: $oxford;
    --knobby: $carribean-green;
    --selectedKnobby: $darker-green;

    appearance: none;
    @include bar-styles;
  }

  // safari
  .progressBar::-webkit-slider-runnable-track {
    @include bar-styles;
  }

  // firefox
  .progressBar::-moz-range-track {
    @include bar-styles;
  }

  .progressBar::-moz-focus-outer {
    border: 0;
  }

  // progress bar - chrome & safari
  .progressBar::before {
    content: '';
    overflow: hidden;
    height: 16px;
    width: var(--seek-before-width);
    background-color: #00072d;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }

  // progress bar - firefox
  .progressBar::-moz-range-progress {
    @include bar-before;
  }

  // knob - chrome & safari
  .progressBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include bar-knob;

    &:active {
      transform: scale(1.2);
      background: $darker-green;
    }
  }

  // knob - firefox
  .progressBar::-moz-range-thumb {
    @include bar-knob;
    border: transparent;

    &:active {
      transform: scale(1.2);
      background: $darker-green;
    }
  }
}
