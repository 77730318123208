@import './mixins.scss';
@import './variables.scss';
@import './media-queries.scss';

.portfolio-container {
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: $celeste;
  background-color: $dark-teal;
  height: calc(100vh - 70px);
  @include page-shadow;

  .portfolio-item {
    display: flex;
    flex-direction: column;
    width: 800px;
    border: 1px solid $celeste;
    border-radius: 5px;
    padding: 15px;

    .title-description {
      h3 {
        font-weight: 400;
      }
    }
  }
}
