@mixin page-shadow {
  box-shadow: inset 0px -26px 275px -8px rgba(0, 0, 0, 0.4);
}

@mixin txt-shadow {
  text-shadow: 0px 0px 15px black;
}

@mixin bar-styles {
  background: $celeste;
  border-radius: 10px;
  position: relative;
  width: 40em;
  height: 15px;
  outline: none;
}

@mixin bar-before {
  background-color: $oxford;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 15px;
}

@mixin bar-knob {
  height: 21px;
  width: 21px;
  border-radius: 50%;
  border: none;
  background-color: $caribbean-green;
  cursor: pointer;
  position: relative;
  margin: -3px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
}
