@import './mixins.scss';
@import './variables.scss';
@import './media-queries.scss';

.left-side {
  display: flex;
  align-items: center;
  padding-left: 60px;

  .brand-name {
    font-size: 2.3em;
    font-weight: 700;
    color: black;
    text-decoration: none;
  }

  img {
    height: 50px;
    margin-right: 5px;
  }
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  background-color: $light-gray;
  padding: 10px;
  box-shadow: inset 0px -26px 75px -8px rgba(0, 0, 0, 0.6);

  .right-side {
    display: flex;
    align-items: center;
    padding-right: 60px;
  }

  .nav-link-wrapper {
    height: 22px;
    border-bottom: 1px solid transparent;
    margin: 0px 20px 0px 20px;
    text-transform: uppercase;
    font-size: 1.2rem;

    .active {
      border-bottom: 1px solid black;
      color: black;
    }

    a {
      color: $celeste;
      justify-content: center;
      text-decoration: none;
      transition: 0.4s ease-in-out;
      position: relative;

      &:hover {
        color: $charcoal;
        letter-spacing: 1.5px;
      }
    }
  }
}
