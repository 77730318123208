@media (max-width: 615px) {
  .nav-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .left-side {
      padding: 0px;
    }

    .right-side {
      padding: 0px;
      margin-top: 10px;
    }
  }

  .portfolio-container {
    width: 100vw;
  }

  .portfolio-items-wrapper {
    width: 100vw;
  }

  // chrome
  .progressBar {
    width: 23em !important;
  }

  // safari
  .progressBar::-webkit-slider-runnable-track {
    width: 23em;
  }

  // firefox
  .progressBar::-moz-range-track {
    width: 23em;
  }

  .about-page-container {
    width: 100vw;

    .about-page-wrapper {
      width: 100vw;

      .left-column,
      .right-column {
        width: 50vw;
      }

      .square {
        width: 50vw;

        .content-wrapper {
          justify-content: center;
          width: 48vw;
          height: 308px;
          font-size: 0.9em;

          p {
            padding-bottom: 10px;
            padding-top: 10px;
          }
        }

        .image-wrapper {
          width: 50vw;
          height: 308px;

          img {
            width: 50vw;
            object-fit: cover;
          }
        }
      }
    }
  }

  .contact-page-wrapper {
    .contact-container {
      justify-content: center;

      .contact-me {
        padding-bottom: 30px !important;
      }

      .bullet-points {
        flex-direction: column !important;
        width: 100vw !important;
        padding: 0px !important;

        .point-group {
          margin-bottom: 20px;
        }
      }
    }
  }
}
