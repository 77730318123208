@import './mixins.scss';
@import './variables.scss';
@import './media-queries.scss';

.about-page-container {
  overflow: hidden;

  .about-page-wrapper {
    display: flex;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    background-color: $dark-teal;
    @include page-shadow;

    .square {
      width: 500px;
    }

    .content-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 500px;
      font-size: 1.2em;
      color: $celeste;

      p {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    // .left-column {
    //   .content-wrapper {
    //     padding-right: 30px;
    //   }
    // }

    // .right-column {
    //   .content-wrapper {
    //     padding-left: 30px;
    //   }
    // }

    .image-wrapper {
      height: 500px;
    }

    .image-wrapper img {
      width: 500px;
      object-fit: cover;
    }
  }
}
